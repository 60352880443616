import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import logo from './Media/logo.png';
import './App.css';
import { TextField, Button, Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import Phone Input Styles
import OTPInput from 'otp-input-react'; // OTP Input component

function App() {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [language, setLanguage] = useState('en');
  const [errorMessage, setErrorMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [phoneValidColor, setPhoneValidColor] = useState('gray'); // Color state for phone number validation
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' }); // Snackbar state

  // Validate Email and Phone
  const validateForm = (emailValue, phoneValue) => {
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
    const phoneDigits = phoneValue.replace(/\D/g, '').slice(-11); // Remove prefix and non-numeric characters
    const phoneValid = phoneDigits.length >= 9 && phoneDigits.length <= 11;
    setIsFormValid(emailValid && phoneValid);
    setPhoneValidColor(phoneValid ? 'green' : phoneValue.length === 0 ? 'gray' : 'red');
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    validateForm(emailValue, phone);
  };

  const handlePhoneChange = (phoneValue) => {
    setPhone(phoneValue);
    validateForm(email, phoneValue);
  };

  const handleSendRequest = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone.replace('+', '')); // Phone without the "+" sign

    axios.post('https://api.ar7ebo.gomaplus.tech/api/deleteUser', formData, {
      headers: {
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: response.data.message, // Display response message
        severity: response.status === 200 ? 'success' : 'error'
      });
      if (response.status === 200) {
        setIsOtpSent(true);
      } else {
        setErrorMessage('Failed to send deletion request.');
      }
    })
    .catch(error => {
      setIsLoading(false);
      console.error('Error:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'An error occurred while sending the request.',
        severity: 'error'
      });
    });
  };

  const handleOtpSubmit = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('phone', phone.replace('+', '')); // Phone without the "+" sign
    formData.append('otp', otp);

    axios.post('https://api.ar7ebo.gomaplus.tech/api/whatsAppVerificationToDeleteUser', formData, {
      headers: {
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: response.data.message, // Display response message
        severity: response.status === 200 ? 'success' : 'error'
      });
      if (response.status === 200) {
        setIsOtpSent(false)
        alert('Account deletion request successful!');
      } else {
        setErrorMessage('OTP verification failed. Please try again.');
      }
    })
    .catch(error => {
      setIsLoading(false);
      console.error('Error:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'An error occurred during OTP verification.',
        severity: 'error'
      });
    });
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className="App" style={{ backgroundColor: '#fff', color: '#00A86B', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ maxWidth: '300px', width: '90%' }}>
        <header className="App-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px', borderBottom: "1px solid #00A86B" }}>
          <img src={logo} alt="logo" style={{ height: '50px' }} />
          <Button
            onClick={() => setLanguage(language === 'en' ? 'ar' : 'en')}
            style={{
              backgroundColor: '#fff',
              color: '#00A86B',
              padding: '10px',
              borderRadius: '8px',
            }}
          >
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              style={{ textAlign: 'center', margin: '0 0' }}
            >
              {language === 'en' ? 'العربية' : 'English'}
            </motion.h1>
          </Button>
        </header>

        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ textAlign: 'center', margin: '20px 0' }}
        >
          {language === 'en' ? 'Account Deletion Request' : 'طلب حذف الحساب'}
        </motion.h1>

        {!isOtpSent ? (
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <TextField
              label={language === 'en' ? 'Email' : 'البريد الإلكتروني'}
              type="email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
              error={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && email.length > 0}
              helperText={
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && email.length > 0
                  ? language === 'en'
                    ? 'Invalid email format'
                    : 'صيغة البريد الإلكتروني غير صحيحة'
                  : ''
              }
              style={{ marginBottom: '20px' }}
            />

            <p style={{ textAlign: 'left', color: '#00A86B' }}>{language === 'en' ? 'Phone Number' : 'رقم الهاتف'}</p>
            <PhoneInput
              country={'sa'} // Default to Saudi Arabia
              value={phone}
              onChange={handlePhoneChange}
              inputStyle={{
                width: '100%',
                paddingLeft: '50px', // Add padding to prevent overlap with the prefix selector
                borderRadius: '8px',
                padding: '10px',
                borderColor: phoneValidColor,
              }}
              placeholder={language === 'en' ? 'Phone Number' : 'رقم الهاتف'}
              searchPlaceholder={language === 'en' ? 'Search for a country' : 'ابحث عن الدولة'}
              countryCodeEditable={false}
              autoFormat={true}
            />
            <p style={{ color: phoneValidColor, textAlign: 'center' }}>
              {phone.replace(/\D/g, '').slice(-11).length < 9 || phone.replace(/\D/g, '').slice(-11).length > 11 ? 
              (language === 'en' ? 'Phone number must be between 9 and 11 digits' : 'يجب أن يتراوح طول رقم الهاتف بين 9 و 11 أرقام') : ''}
            </p>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isFormValid || isLoading}
              onClick={handleSendRequest}
              style={{
                backgroundColor: '#00A86B',
                marginTop: '20px',
                padding: '10px',
                borderRadius: '8px',
              }}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : language === 'en' ? 'Send Request' : 'إرسال الطلب'}
            </Button>
          </motion.div>
        ) : (
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
          >
            <p style={{ textAlign: 'center' }}>
              {language === 'en' ? 'Confirm your phone number ' : 'تأكيد رقم الهاتف'}
            </p>
            <Box sx={{ justifyContent: "center" }}>
              <OTPInput
                value={otp}
                onChange={(value) => {
                  const numericValue = value.replace(/\D/g, ''); // Ensure only numeric values are allowed
                  setOtp(numericValue);
                }}
                length={4}
                separator={<span>-</span>}
                autoFocus
                inputStyle={{
                  border: '1px solid #00A86B',
                  borderRadius: '8px',
                  width: '40px',
                  height: '40px',
                  fontSize: '20px',
                  textAlign: 'center',
                  margin: '5px',
                }}
                focusStyle={{
                  outline: 'none',
                  borderColor: '#00A86B',
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={otp.length !== 4 || isLoading}
              onClick={handleOtpSubmit}
              style={{
                backgroundColor: '#00A86B',
                marginTop: '20px',
                padding: '10px',
                borderRadius: '8px',
              }}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : language === 'en' ? 'Verify OTP ' : 'تأكيد الرمز'}
            </Button>
            {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
          </motion.div>
        )}

        {/* Snackbar for success and error messages */}
        <Snackbar open={snackbar.open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default App;